<script setup lang="ts">
const currYear = new Date().getFullYear();
</script>

<template>
  <div class="footer-wrapper">
    <footer class="footer">
      <p class="copyright font--b6--r">© Copyright, {{ currYear }}</p>
      <nuxt-link to="/policy" class="hover-scale-light font--b5--b policy"
        >Privacy Policy</nuxt-link
      >
    </footer>
  </div>
</template>

<style scoped lang="scss">
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1350px;
  padding: 15px 140px;
  margin: 0 auto;

  &-wrapper {
    width: 100%;
    border-top: 1px solid var(--bl-80);
    background: var(--bg);
  }
}

.policy {
  line-height: 24px;
  text-transform: capitalize;
  text-decoration: none;
  color: var(--bl);
}

@media (max-width: 1100px) {
}
</style>
