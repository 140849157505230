<script setup lang="ts">
</script>

<template>
  <div class="content">
    <div class="content-wrapper">
      <app-header />
      <main class="wrapper">
        <slot ></slot>
      </main>
      <app-footer-custom />
    </div>
  </div>
</template>

<style scoped lang="scss">
.content {
  overflow-x: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg);

  &-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
</style>